@charset 'UTF-8';
@use '../global/' as *;



// Input 要素
//
.input {
  background-color: $gray-200;
  border: $component-border-width solid transparent;
  border-radius: $component-border-radius;
  box-shadow: none;
  color: $gray-dark;
  font-size: $form-font-size;
  line-height: 1;
  padding: $input-y-padding $input-x-padding;
  width: 100%;
  transition: border-color $base-transition, background-color $base-transition;

  // プレースホルダー
  &::placeholder {
    font-size: $placeholder-font-size;
    transition: color $base-transition;
  }
  &::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $placeholder-color;
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }
  &::-ms-expand {
    background-color: $white;
    border: 0;
  }

  // File input
  &[type="file"] {
    font-size: 1rem;
    overflow: hidden;
    padding: .75em .5em;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &::file-selector-button {
    @include file-selector-button($input-x-padding, $input-y-padding);
  }
  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $gray-200;
  }

  &::-webkit-file-upload-button {
    @include file-selector-button($input-x-padding, $input-y-padding);
  }
  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $gray-200;
  }

  // フォーカス
  &:focus,
  &:focus:not(:placeholder-shown) {
    background-color: $white;
    border-color: $base;
    box-shadow: 0 0 6px 1px rgba( $gray-dark, .075 );
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  // 入力済み
  &:not(:placeholder-shown) {
    background-color: $gray-100;
    border: $component-border-width solid $component-border-color;
  }

  // テキストエリア
  @at-root textarea#{&} {
    font-family: $base-font-family;
    line-height: 1.6;
    height: auto;
    overflow-wrap: break-word;
    padding: 1em;
    resize: vertical;
    width: 100%;
  }

  & + & {
    margin-top: var(--form-grid-gutter);
  }

  // error
  &.error {
    border: $component-border-width solid $red;
  }
}
