@charset 'UTF-8';
@use './global/' as *;



.three-point-container {
  display: grid;
  grid-auto-rows: minmax(0, 1fr);
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 2rem;
  row-gap: 3.5rem;

  @include media(sm){
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include media(lg){
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .three-point-item {
    display: block;
    .link-item {
      color: $gray-dark;
      display: block;
    }
    .item-image {
      width: 100%;
      height: 100%;
      img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
      }
    }
  }
}
