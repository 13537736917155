@charset 'UTF-8';
@use './global/' as *;



.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem $container-side-padding;
  width: 100%;
  max-width: 1440px;
}



.header-left-column {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}



.header-right-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-left: 1rem;
  z-index: 1;
}



.header-brand {
  font-weight: 700;
  a {
    color: $gray-dark;
    font-size: 1.25rem;
    @include hover(){
      color: $gray-600;
    }
  }
}
