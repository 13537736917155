@charset 'UTF-8';
@use '../global/' as *;



.form-container {
  background-color: $white;
  border: $form-container-border;
  border-radius: .5rem;
  box-shadow: 0 .125rem .25rem rgba( #000, .075 );
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: $form-container-padding;
  width: 100%;
  max-width: $form-container-max-width;

  & + &,
  & + .h-adr,
  .h-adr + & {
    margin-top: $form-container-gutter;
  }
}

.form-container-spacer {
  margin: $form-container-gutter 0;
}

.form-container-spacer-top {
  margin: $form-container-gutter 0 0;
}

.form-container-spacer-bottom {
  margin: 0 0 $form-container-gutter;
}



// フォームの項目名表示
.form-title {
  background-color: $title-background-color;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: .5em;


  // 項目名
  .title {
    background-color: transparent;
    color: $gray-dark;
    font-size: .875em;
    font-weight: 700;
  }

  // 必須と任意
  .optional,
  .required {
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    margin: 0 .5rem;
    padding: .4em .4em calc( .4em - 1px );
  }
  // 任意
  .optional {
    background-color: $optional-background-color;
    color: $optional-color;
  }
  // 必須
  .required {
    background-color: $required-background-color;
    color: $required-color;
  }
}



// フォーム入力表示
.form-value {
  background-color: $value-background-color;
}
