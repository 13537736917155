@charset 'UTF-8';

$base  : #e70d50 !default;
$accent: #3ba1f9 !default;
$uno   : #0e67b5 !default;
$dos   : #1c5282 !default;
$tres  : #325675 !default;
$cuatro: #2da8a8 !default;
$cinco : #5f6987 !default;

$theme-colors: (
  'base'  : $base,
  'accent': $accent,
  'uno'   : $uno,
  'dos'   : $dos,
  'tres'  : $tres,
  'cuatro': $cuatro,
  'cinco' : $cinco,
  'first' : $uno,
  'second': $dos,
  'third' : $tres,
  'fourth': $cuatro,
  'five'  : $cinco
) !default;



$red   : #b0120a !default;
$yellow: #fff000 !default;
$orange: #f18f2c !default;
$green : #63a764 !default;
$blue  : #005fcc !default;
$pink  : #ff0066 !default;
$purple: #6f42c1 !default;
$cyan  : #17a2b8 !default;

$commons-colors: (
  'red'   : $red,
  'yellow': $yellow,
  'orange': $orange,
  'green' : $green,
  'blue'  : $blue,
  'pink'  : $pink,
  'purple': $purple,
  'cyan'  : $cyan
) !default;



$gray-100 : #f8f9fa !default;
$gray-200 : #e9ecef !default;
$gray-300 : #dee2e6 !default;
$gray-400 : #ced4da !default;
$gray-500 : #adb5bd !default;
$gray-600 : #6c757d !default;
$gray-700 : #495057 !default;
$gray-800 : #343a40 !default;
$gray-900 : #212529 !default;
$gray-dark: #333    !default;
$black    : #000    !default;
$white    : #fff    !default;

$grays: (
  'gray-100':  $gray-100,
  'gray-200':  $gray-200,
  'gray-300':  $gray-300,
  'gray-400':  $gray-400,
  'gray-500':  $gray-500,
  'gray-600':  $gray-600,
  'gray-700':  $gray-700,
  'gray-800':  $gray-800,
  'gray-900':  $gray-900,
  'gray-dark': $gray-dark,
  'black':     $black,
  'white':     $white
) !default;



// カラーの配列を作る
//
// グレースケールと汎用カラーの配列
$basic-colors: map-merge(
  $grays,
  $commons-colors
);

// テーマカラーを含めたすべての配列
$all-colors: map-merge(
  $theme-colors,
  $basic-colors
);
