@charset 'UTF-8';
@use './global/' as *;
@use 'sass:math';





.headline-container {
  margin: 0 0 2rem;
}



.articles-wrapper {
  width: 100%;
}



$article-list-gap: 2rem;

.articles-list-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: $article-list-gap;
}



.article-item {
  background-color: $white;
  border-radius: .5rem;
  box-shadow: $base-box-shadow-small;
  flex: 0 0 100%;
  overflow: hidden;
  position: relative;
  @include media(sm){
    flex: 0 0 calc(50% - math.div($article-list-gap, 2));
  }

  .eye-catch {
    width: 100%;
    height: 50vw;
    @include media(sm){
      height: min(26vw, 348px);
    }
    img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}



.article-link {
  inset: 0px;
  position: absolute;
}







.article-item-contents {
  padding: 1.5rem;

  h2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 1.142857em;
    line-height: 1.5;
    margin: 0;
    max-height: 4.5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}



.article-item-status {
  .article-date {
    color: $gray-600;
    display: flex;
    align-items: center;
    font-size: .75em;
    font-weight: 700;
    margin: 2rem 0;
    &::before {
      content: '';
      background-image: set-svg($update, $gray-600);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      width: 1.25em;
      height: 1.25em;
    }
  }

  .category-container {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: .5rem;
    //justify-content: flex-end;
    .category-item {
      //margin-right: .5rem;
      a {
        background-color: $white;
        border: 1px solid $gray-500;
        border-radius: 2px;
        color: $gray-600;
        display: block;
        font-size: .75em;
        line-height: 1;
        padding: .5em 1em;
        position: relative;
        z-index: 1;
        transition: color $base-transition, border-color $base-transition;
        @include hover(){
          border-color: $gray-dark;
          color: $gray-dark;
        }
      }
    }
  }
}
