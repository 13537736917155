@charset 'UTF-8';
@use './global/' as *;



$animals: (
  'sheep': '/images/dialogue/sheep.svg',
  'chicken': '/images/dialogue/chicken.svg',
  'penguin': '/images/dialogue/penguin.svg',
  'cat': '/images/dialogue/cat.svg'
) !default;

$icon-size: 70px !default;



.dialogue-container {
  margin: 4rem auto;
  width: 100%;
  @include media(md){
    width: 80%;
  }
}



.dialogue {
  display: flex;
  flex-direction: row;

  & + & {
    margin: 4rem 0 0;
  }

  .icon {
    background-color: $gray-200;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 92%;
    border-radius: 50%;
    flex: 0 0 $icon-size;
    width: $icon-size;
    height: $icon-size;
    order: 0;

    @each $name, $url in $animals {
      &.#{$name} {
        background-image: url('#{$url}');
      }
    }
  }

  .text {
    border: 2px solid $gray-300;
    border-radius: 8px;
    margin: 0 0 0 1rem;
    order: 1;
    padding: 1.2em;
    position: relative;
    &::before {
      border: 10px solid transparent;
      border-right: 10px solid $gray-300;
      content: '';
      position: absolute;
      top: 21px;
      left: -22px;
    }
    &::after {
      border: 10px solid transparent;
      border-right: 10px solid $white;
      content: '';
      position: absolute;
      top: 21px;
      left: -19px;
    }
  }

  &.right {
    justify-content: flex-end;
    .icon {
      order: 1;
    }

    .text {
      margin: 0 1rem 0 0;
      order: 0;
      &::before {
        border: 10px solid transparent;
        border-left: 10px solid $gray-300;
        content: '';
        position: absolute;
        top: 21px;
        right: -22px;
        left: unset;
      }
      &::after {
        border: 10px solid transparent;
        border-left: 10px solid $white;
        content: '';
        position: absolute;
        top: 21px;
        right: -19px;
        left: unset;
      }
    }
  }
}
