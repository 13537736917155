@charset 'UTF-8';
@use './global/' as *;
@use 'sass:math';





.article-container {
  margin: 0 auto;
  width: min(100%, 800px);
}



$article-gutter: 3rem;
$article-contents-gap: 1rem;

.article-header {
  padding: 0 $article-contents-gap;
  position: relative;

  .article-eye-catch {
    margin: 0 0 $article-gutter;
    overflow: hidden;

    .eye-catch {
      display: block;
    }
  }

  h1 {
    font-size: 2rem;
    line-height: 1.5;
    margin: 0 0 1em;
  }

  .create-at {
    color: $gray-600;
    display: flex;
    align-items: center;
    font-size: .75em;
    font-weight: 700;
    &::before {
      content: '';
      background-image: set-svg($update, $gray-600);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      width: 1.25em;
      height: 1.25em;
    }
  }
}



.article-contents-wrapper {
  margin: $article-gutter 0 0;
  padding: 0;
  @include media(sm){
    padding: 0 $article-contents-gap;
  }
}



.article-contents-container {
  background-color: $white;
  box-shadow: $base-box-shadow-small;
  padding: ($article-contents-gap * 2) $article-contents-gap;
  @include media(sm){
    border-radius: 12px;
    padding: ($article-contents-gap * 2);
  }

  .category-container {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: .5rem;
    margin: 0 0 2rem;
    .category-item {
      //margin-right: .5rem;
      a {
        border: 1px solid $gray-500;
        border-radius: 2px;
        color: $gray-600;
        display: block;
        font-size: .75em;
        line-height: 1;
        padding: .5em 1em;
        position: relative;
        z-index: 1;
        transition: color $base-transition, border-color $base-transition;
        @include hover(){
          border-color: $gray-dark;
          color: $gray-dark;
        }
      }
    }
  }

  .article-contents {
    line-height: 1.9;

    h1, h2, h3, h4, h5, h6 {
      line-height: 1.5;
      margin-top: 2rem;
      margin-bottom: 1em;
      &:first-child {
        margin-top: 0;
      }
    }

    h2 {
      border-bottom: 1px solid $gray-300;
      font-size: 1.5em;
      padding-bottom: .3em;
    }

    h3 {
      font-size: 1.25em;
    }

    > p {
      margin-top: 1.5rem;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}



.article-pager-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 6rem 0 0;
  @include media(sm){
    gap: 0;
  }
}



.article-pager-item {
  display: flex;
  justify-content: flex-start;
  flex: 0 0 100%;
  @include media(sm){
    flex: 0 0 48%;
  }
  &.next {
    justify-content: flex-end;
  }

  a {
    color: $gray-dark;
    display: flex;
    align-items: center;
    font-size: .875em;
    transition: color $base-transition;
    @include hover(){
      color: $gray-600;
    }

    $icon-size: 2em;

    &.prev {
      &::before {
        background-image: set-svg($chevron-left, $gray-600);
        content: '';
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        flex: 0 0 auto;
        width: $icon-size;
        height: $icon-size;
      }
    }

    &.next {
      &::after {
        background-image: set-svg($chevron-right, $gray-600);
        content: '';
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        flex: 0 0 auto;
        width: $icon-size;
        height: $icon-size;
      }
    }
  }
}
