@charset 'UTF-8';
@use './global/' as *;



.global-nav-wrapper {
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: opacity $base-transition, visibility $base-transition;
  #{$collapse-class-name} & {
    opacity: 1;
    visibility: visible;
  }
}




.global-nav-container {
  margin: 0 auto;
  padding: 6rem 2rem 2rem;
  width: min(100%, 800px);
}

.global-nav-headline {
  font-size: 1.75rem;
  margin: 0 0 4rem;
}

.global-nav-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  li {

    a {
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: 500;
    }
  }
}
