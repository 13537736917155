@charset 'UTF-8';
@use './global/' as *;



h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-weight: $headings-font-weight;
  line-height: $heading-line-height;
  margin-top: 0;
  //margin-bottom: $heading-margin-bottom;
  text-rendering: optimizeLegibility;
}



h1 {
  font-size: $h1-font-size;
}



h2 {
  font-size: $h2-font-size;
}



h3 {
  font-size: $h3-font-size;
}



h4 {
  font-size: $h4-font-size;
}



h5 {
  font-size: $h5-font-size;
}



h6 {
  font-size: $h6-font-size;
}



// Paragraph
.lead {
  font-size: inherit;
  letter-spacing: $lead-letter-spacing;
  line-height: $lead-line-height;
  text-rendering: optimizeLegibility;
  // .lead spacer
  & + & {
    margin-top: $lead-top-spacer;
  }
}



// 注釈
.notes {
  padding-left: 1em;
  text-indent: -1em;
  &::before {
    content: '※';
    font-family: sans-serif;
  }
}
