@charset 'UTF-8';
@use './global/' as *;



.burger {
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: '';
    background-image: set-svg($burger, $gray-dark);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    transition: background-image $base-transition;
    width: 24px;
    height: 24px;
  }
  span {
    display: block;
    font-size: .75em;
    font-weight: 500;
    margin-left: .25em;
  }
  #{$collapse-class-name} & {
    &::before {
      background-image: set-svg($dismiss, $gray-dark);
    }
  }
}
