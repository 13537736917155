@charset 'UTF-8';
@use './global/' as *;



:root {

  // root font size
  @each $key, $size in $root-font-size {
    @include media($key, true){
      --root-font-size: #{$size};
    }
  }

  // grid
  @each $key, $value in $breakpoints {
    @include media($key, true){
      --grid-gutter: #{map-get( $grid-gutters, $key )};
    }
  }

  // form grid
  --form-grid-gutter: #{$form-grid-gutter};

}



*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

:where(pre) {
  all: revert;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  line-break: after-white-space;
  -webkit-line-break: after-white-space;
  user-select: auto;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}



html {
  font-size: var(--root-font-size);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}



body {
  background-color: $base-background-color;
  color: $base-text-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), ( min-resolution: 2dppx ) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &#{$collapse-class-name} {
    overflow: hidden;
  }
}



// Links
a {
  color: $base-link-color;
  cursor: revert;
  text-decoration: $base-link-decoration;
  @media (hover: hover){
    &:hover {
      color: $base-link-hover-color;
      text-decoration: $base-link-hover-decoration;
    }
  }
}



// Images
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}



// Horizontal line
hr {
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: $hr-y-gutter 0;
  width: 100%;
  height: 0;
}



table {
  border-collapse: collapse;
}



ol, ul, menu {
  list-style: none;
}



b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}
