@charset 'UTF-8';
@use './global/' as *;



.code-block-container {
  margin: $base-gutter 0;
  position: relative;

  .type {
    background-color: $gray-100;
    color: $gray-dark;
    font-size: .75em;
    font-weight: 700;
    padding: .375em 1em;
    position: absolute;
    top: 0;
    left: 0;
  }
}



pre {
  background-color: #1a2638;
  line-height: 1.9;
  overflow-x: auto;
  padding: 1.5em;
  white-space: pre-wrap;
  word-wrap: break-word;
}



code {
  background-color: transparent;
  color: $white;
  font-family: 'SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', monospace, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: .875em;
  font-weight: 400;
  vertical-align: initial;
}
