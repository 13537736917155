@charset 'UTF-8';
@use './global/' as *;



.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem $container-side-padding;
  width: 100%;
  max-width: 1440px;
}



.footer-left-column {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}



.footer-right-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-left: 1rem;
}



.footer-brand {
  margin: 0 0 1rem;
  img {
    display: block;
    width: auto;
    height: 1.25em;
  }
}



.footer-legal {
  background-color: $gray-100;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}



.footer-legal-copyright {
  color: $white;
  font-size: .75rem;
}
